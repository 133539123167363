<template>
  <div>
    <vue-headful :title="pageTitle"/>
    <div
      id="content-page-title"
      class="has-text-centered has-background-primary"
      style="margin-bottom: 20px;"
    >
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading/>
      </div>

      <div v-else id="body-content-area-all">
        <div id="pagelayout">
        <form @submit.prevent="handleSubmit('/sites/settings' ,{ name: 'Settings'}, true)">
            <div class="columns" style="width: 100%;">
              <div class="column">
                <!-- ====== PASSWORD REQUIREMENTS ====== -->
                <div id="password-requirements-section">
                  <div
                    class="block"
                    style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                  >
                    <span class="has-text-weight-bold">Password Requirements</span>
                  </div>

                  <!-- Password Minimum Length -->
                  <div id="pwdMinimumLength" class="field">
                    <label class="label is-size-7">
                      Minimum Length
                      <span class="has-text-warning" style="padding-left: 3px;">*</span>
                    </label>
                    <div class="field is-flex">
                      <div class="control">
                        <input
                          class="input"
                          v-validate="'required|numeric'"
                          data-vv-as="'Minimum Length'"
                          name="pwdMinimumLength"
                          v-model="input.minLength"
                          style="width: 4rem;"
                        >
                      </div>
                      <div style="margin: 8px 0 0 0.75rem;">characters</div>
                    </div>
                    <div>
                      <span class="has-text-danger is-size-7">{{ errors.first('pwdMinimumLength') }}</span>
                    </div>
                  </div>

                  <!-- Password Minimum Number of Each Character Type -->
                  <div>
                    <label class="label is-size-7">
                      Minimum Number of Each Character Type
                      <span
                        class="has-text-warning"
                        style="padding-left: 3px;"
                      >*</span>
                    </label>

                    <div id="minimum-character-types">
                      <div class="is-flex" style="flex-flow: row wrap; padding-bottom:0px;">
                        <!-- Upper Alpha -->
                        <div class="field is-grouped">
                          <input
                            class="input"
                            v-validate="'required|numeric'"
                            data-vv-as="'Uppercase Alphabetic'"
                            name="pwdUpperAlpha"
                            v-model="input.minAlphaUpper"
                            style="width: 4rem;"
                          >
                          <div
                            style="padding-top: 7px; padding-left: 10px; padding-right: 30px;"
                          >A-Z</div>
                        </div>
                        <!-- Lower Alpha -->
                        <div class="field is-grouped">
                          <input
                            class="input"
                            v-validate="'required|numeric'"
                            data-vv-as="'Lowercase Alphabetic'"
                            name="pwdLowerAlpha"
                            v-model="input.minAlphaLower"
                            style="width: 4rem;"
                          >
                          <div
                            style="padding-top: 7px; padding-left: 10px; padding-right: 30px;"
                          >a-z</div>
                        </div>
                        <!-- Numeric -->
                        <div class="field is-grouped">
                          <input
                            class="input"
                            v-validate="'required|numeric'"
                            data-vv-as="'Numeric Characters'"
                            name="pwdNumeric"
                            v-model="input.minNumeric"
                            style="width: 4rem;"
                          >
                          <div
                            style="padding-top: 7px; padding-left: 10px; padding-right: 30px;"
                          >0-9</div>
                        </div>
                        <!-- Symbol -->
                        <div class="field is-grouped">
                          <input
                            class="input"
                            v-validate="'required|numeric'"
                            data-vv-as="'Symbolic Characters'"
                            name="pwdSymbol"
                            v-model="input.minSymbol"
                            style="width: 4rem;"
                          >
                          <div
                            style="padding-top: 7px; padding-left: 10px; padding-right: 30px;"
                          >!@#$</div>
                        </div>
                      </div>
                      <div style="padding-bottom: 0.75em;">
                        <div>
                          <span
                            class="has-text-danger is-size-7"
                          >{{ errors.first('pwdUpperAlpha') }}</span>
                        </div>
                        <div>
                          <span
                            class="has-text-danger is-size-7"
                          >{{ errors.first('pwdLowerAlpha') }}</span>
                        </div>
                        <div>
                          <span class="has-text-danger is-size-7">{{ errors.first('pwdNumeric') }}</span>
                        </div>
                        <div>
                          <span class="has-text-danger is-size-7">{{ errors.first('pwdSymbol') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Password Expiration -->
                  <div id="pwdExpiration" class="field">
                    <label class="label is-size-7">
                      Must change password after
                      <span
                        class="has-text-warning"
                        style="padding-left: 3px;"
                      >*</span>
                    </label>
                    <div class="field is-flex">
                      <div class="control">
                        <input
                          class="input"
                          v-validate="'required|numeric'"
                          data-vv-as="'Password Expiration'"
                          name="pwdExpiration"
                          v-model="input.changeDays"
                          style="width: 4rem;"
                        >
                      </div>
                      <div style="margin: 8px 0 0 0.75rem;">days</div>
                    </div>
                    <div>
                      <span class="has-text-danger is-size-7">{{ errors.first('pwdExpiration') }}</span>
                    </div>
                  </div>

                  <!-- Previous Password Usage -->
                  <div id="pwdPrevious" class="field">
                    <label class="label is-size-7">
                      Cannot reuse old passwords
                      <span
                        class="has-text-warning"
                        style="padding-left: 3px;"
                      >*</span>
                    </label>
                    <div class="field is-flex">
                      <div class="control">
                        <input
                          class="input"
                          v-validate="'required|numeric'"
                          data-vv-as="'Previous Passwords'"
                          name="pwdPrevious"
                          v-model="input.disallowedOld"
                          style="width: 4rem;"
                        >
                      </div>
                      <div style="margin: 8px 0 0 0.75rem;">previous passwords</div>
                    </div>
                    <div>
                      <span class="has-text-danger is-size-7">{{ errors.first('pwdPrevious') }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="column">
                <!-- ====== SESSION SETTINGS ====== -->
                <div id="session-settings-section">
                  <div
                    class="block"
                    style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                  >
                    <span class="has-text-weight-bold">Session Settings</span>
                  </div>

                  <!-- Session Timeout -->
                  <div id="sessionTimeout" class="field">
                    <label class="label is-size-7">
                      Log user out after
                      <span class="has-text-warning" style="padding-left: 3px;">*</span>
                    </label>
                    <div class="field is-flex">
                      <div class="control">
                        <input
                          class="input"
                          v-validate="'required|numeric'"
                          data-vv-as="'Session Timeout'"
                          name="sessionTimeout"
                          v-model="input.inactivityMin"
                          style="width: 4rem;"
                        >
                      </div>
                      <div style="margin: 8px 0 0 0.75rem;">minutes of inactivity</div>
                    </div>
                    <div>
                      <span class="has-text-danger is-size-7">{{ errors.first('sessionTimeout') }}</span>
                    </div>
                  </div>
                </div>

                <!-- ====== FIREWALL SETTINGS ====== -->
                <div id="firewall-settings-section" style="padding-top: 40px;">
                  <div
                    class="block"
                    style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;"
                  >
                    <span class="has-text-weight-bold">Firewall Settings</span>
                  </div>
                  <!-- Failed Login Attempts -->
                  <div class="field">
                    <label class="label is-size-7">
                      Lock account after
                      <span class="has-text-warning" style="padding-left: 3px;">*</span>
                    </label>
                    <div id="brute-force-failed-logins">
                      <div class="field is-flex" style="flex-flow: row wrap;">
                        <div class="field is-grouped" style="margin-top: 5px;">
                          <input
                            class="input"
                            v-validate="'required|numeric'"
                            data-vv-as="'Failed Attempts'"
                            name="failedAttempts"
                            v-model="input.failedLoginAttempts"
                            style="width: 4rem;"
                          >
                          <div
                            class="control"
                            style="padding-top: 7px; padding-left: 10px; padding-right: 10px;"
                          >failed login attempts in</div>
                        </div>
                        <div class="field is-grouped" style="margin-top: 5px;">
                          <input
                            class="input"
                            v-validate="'required|numeric'"
                            data-vv-as="'Failed Attempt Duration'"
                            name="failedDuration"
                            v-model="input.failedLoginHours"
                            style="width: 4rem;"
                          >
                          <div class="control" style="padding-top: 7px; padding-left: 10px;">hours</div>
                        </div>
                      </div>
                      <div>
                        <span class="has-text-danger is-size-7">{{ errors.first('failedAttempts') }}</span>
                      </div>
                      <div>
                        <span class="has-text-danger is-size-7">{{ errors.first('failedDuration') }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- Forgotten Password Attempts -->
                  <div id="brute-force-forgotten-passwords">
                    <div class="field is-flex" style="flex-flow: row wrap;">
                      <div class="field is-grouped" style="margin-top: 5px;">
                        <input
                          class="input"
                          v-validate="'required|numeric'"
                          data-vv-as="'Forgotten Attempts'"
                          name="forgottenAttempts"
                          v-model="input.forgottenPasswordAttempts"
                          style="width: 4rem;"
                        >
                        <div
                          class="control"
                          style="padding-top: 7px; padding-left: 10px; padding-right: 10px;"
                        >forgotten password attempts in</div>
                      </div>
                      <div class="field is-grouped" style="margin-top: 5px;">
                        <input
                          class="input"
                          v-validate="'required|numeric'"
                          data-vv-as="'Forgotten Attempt Duration'"
                          name="forgottenDuration"
                          v-model="input.forgettenPasswordHours"
                          style="width: 4rem;"
                        >
                        <div class="control" style="padding-top: 7px; padding-left: 10px;">hours</div>
                      </div>
                    </div>
                    <div>
                      <span
                        class="has-text-danger is-size-7"
                      >{{ errors.first('forgottenAttempts') }}</span>
                    </div>
                    <div>
                      <span
                        class="has-text-danger is-size-7"
                      >{{ errors.first('forgottenDuration') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="buttongroup">
              <div class="field is-grouped">
                <div class="control">
                  <button type="submit" class="button is-accent has-text-white">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
let pageName = 'Settings'

export default {
  components: {
    Loading
  },
  data () {
    return {
      input: {
        isLoading: false,
        id: 0,
        minLength: 0,
        minAlphaUpper: 0,
        minAlphaLower: 0,
        minNumeric: 0,
        minSymbol: 0,
        changeDays: 0,
        disallowedOld: 0,
        inactivityMin: 0,
        failedLoginAttempts: 0,
        failedLoginHours: 0,
        forgottenPasswordAttempts: 0,
        forgettenPasswordHours: 0
      },
      inputDefault: {

      },
      isLoading: false,
      pageheading: pageName
    }
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    }
  },
  created () {
    this.getSettings()
  },
  methods: {
    getSettings () {
      this.isLoading = true
      this.$http.get('sites/settings').then(response => {
        this.setupForm(response.data)
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
#body-content-area-all {
  overflow-y: hidden;
  position: absolute;
  top: 35px;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  padding: 20px;
}
</style>
